<template>
  <div class="row m-auto col-md-12">
    <div class="col-md-9 ml-auto">
      <div class="row">
        <div class="col-md-2 m-auto">
          <button
            type="button"
            class="btn btn_secondary"
            @click.prevent="$emit('closeModal')"
          >
            Cancelar
          </button>
        </div>
        <div class="col-md-4 m-auto">
          <button
            type="button"
            @click="$emit('submitform')"
            :class="
              `btn ${nameButton == 'Excluir' ? 'btn_delete' : 'btn_default'}`
            "
          >
            {{ nameButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["nameButton"]
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
